import Vue from 'vue'
import { createStore } from 'vuex'
import { SimStateUninitialized } from '@/utils/SimulationState'
import { SimulationStates } from "../utils/SimulationState"
import api from "../helpers/api"

const NUM_NODES = 4
const TIME_MULTIPLIER = 2
const WAIT_FOR_TX_INTERVAL = 5 // How long to wait for UUTs before creating NTB
const WAIT_FOR_NTB_INTERVAL = 5 // HOW long to wait for NTBs before creating HTB
const SPLIT_TIMER_TICKS = 3 // Default # blocks to wait before resolving dishonest behavior.  Set GeeqNode.BAD_BEHAVIOR_START_BLOCK to start bad behavior at a certain block.

export default createStore({
    state: {
        splitTimer: SPLIT_TIMER_TICKS, // How many ticks to wait before resolving honesty
        splitDetected: false, // True, while a split detected and hasn't been resolved which node group is honest

        restartSimOnMount: false,
        numNodes: NUM_NODES,
        timeMultiplier: TIME_MULTIPLIER,

        // Simulation clock tick
        clockTick: 0,

        // Time interval to wait for UUTs before sending NTB to Hub
        waitForTxInterval: WAIT_FOR_TX_INTERVAL,

        // Time interval to wait for NTBs before sending HTB to non-hub nodes.
        waitForNtbInterval: WAIT_FOR_NTB_INTERVAL,

        // Geeq Nodes in simulation
        geeqNodes: new Array(0), // Array of GeeqNode objects
        simulationState: SimulationStates.SIM_STATE_PAUSED,
        isChromeBrowser: false,
        isMobileDevice: false,
        availableTokens: 0,
        isExtensionExists: false,
        isReadyToStart: false
    },
    mutations: {
        updateRestartSim(state, doRestart) {
            state.restartSimOnMount = doRestart
        },
        setIsReadyToStart(state, isReady) {
            state.isReadyToStart = isReady
        },
        setNumNodes(state: any, n: number) {
            state.numNodes = n
        },
        setIsChromeBrowser(state: any, isChrome: boolean) {
            state.isChromeBrowser = isChrome
        },
        setIsMobileDevice(state: any, isMobile: boolean) {
            state.isMobileDevice = isMobile
        },
        setAvailableTokensCount(state: any, count: boolean) {
            state.availableTokens = count
        },
        setIsExtensionExists(state: any, isExtensionExists: boolean) {
            state.isExtensionExists = isExtensionExists
        },
        updateSimulationState(state: any, newState: number) {
            state.simulationState = newState
        },
        // Update Clock tick
        setClockTick(state: any, n: number) {
            state.clockTick = n
        },
        incrementClockTick(state: any) {
            state.clockTick += 1
        },
        decrementSplitTimer(state: any) {
            state.splitTimer = state.splitTimer - 1
        },
        updateSplitDetected(state: any, newState: any) {
            state.splitDetected = newState
        },
        updateGeeqNodes(state: any, nodes: any) {
            state.geeqNodes = nodes
        }
    },
    actions: {
         setSimulatorStatus({ commit }, state) {
             api.getSimulatorStatus()
                .then((res) => {
                    const result = res.data.result;

                    if (result.status === 'start') {
                        const currentTime = new Date();
                        const timestamp = result.startedAt
                        // const timestamp = 1648840251143
                        let clockTick = 0

                        if (timestamp) {
                            const timeStarted = new Date(timestamp);
                            clockTick = ( currentTime.getTime() - timeStarted.getTime() ) / 1000;
                        }
                        commit('setClockTick', clockTick)
                        commit('updateSimulationState', SimulationStates.SIM_STATE_RUNNING)
                    } else {
                        commit('setClockTick', 0)
                        commit('updateSimulationState', SimulationStates.SIM_STATE_PAUSED)
                    }
                })
        }
    },
    getters: {
        getGeeqNodes: (state) => state.geeqNodes,
        getSimulationState: (state) => state.simulationState,
        getIsReadyToStart: (state) => state.isReadyToStart,
    },
})
