//import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.css'
import 'vue3-carousel/dist/carousel.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueAxios, axios)
app.mount('#app')

