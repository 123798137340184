<template>
  <footer class="py-20">
    <div class="footer-container">
      <div>
        <img src="/img/geeq-png.png" class="max-w-[25rem] 3xl:max-w-[30rem] 6xl:max-w-xl mx-auto"/>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FooterComponent',
  computed: mapState({
    //
  }),
}
</script>

<style scoped>
.footer-container {
  margin: 30px 0;
  padding: 40px 20px;
}
footer {
  background: #f3f3f1;
}
</style>
