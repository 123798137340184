<template>
  <header>
    <div class="main-content-area">
      <div class="header-bar-left">
      <div
          class="inline-block logo-wrap my-5 w-auto"
          itemscope="itemscope"
          itemtype="https://schema.org/Organization"
          style="min-width: 195px"
      >
        <a
            href="https://geeq.io"
            title="go to the Geeq main website"
            class="logo-link"
        >
          <img src="/img/Geeq_green.svg" />
        </a>
      </div>
      <!-- end header-logo-wrap -->
    </div>
      <!-- end header-bar-left -->

      <div class="control-bar control-bar-bg-color">
        <div class="inner-margin">
          <div class="control-bar-left text-3xl">
            <h1>Micropayments TF v{{ version }}</h1>
          </div>
          <!-- end control-bar-left -->

          <div class="control-bar-right">
            <div class="timer-block-wrap">
              <div class="timer-wrap">Timer: {{ elapsedTime() }}</div>
              <!-- end timer-wrap -->

              <div class="timer-button-wrap">
                <button
                    v-if="showStartButton"
                    @click="startSim"
                    :class="`bg-white start-button text-blue-dark timer-button ${!isReadyToStart || !geeqNodes.length ? 'disabled': ''}`"
                >
                  Start
                </button>
                <button
                    v-if="showPauseButton"
                    @click="pauseSim"
                    class="timer-button pause-button bg-white text-blue-dark"
                >
                  Stop
                </button>
              </div>
              <!-- end timer-button-wrap -->
            </div>
            <!-- end timer-block-wrap -->
          </div>
          <!-- end control-bar-right -->
        </div>
        <!-- end inner-margin -->
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SimStateInitial,
  SimStateRunning,
  SimStatePaused,
} from '@/utils/SimulationState'
import api from "../helpers/api"

export default {
  name: 'HeaderComponent',
  data: () => ({
    showMenu: false,
    version: process.env.VUE_APP_VERSION,
  }),
  computed: {
    ...mapGetters({
      geeqNodes: 'getGeeqNodes',
      simulationState: 'getSimulationState'
    }),
    isReadyToStart() {
      return this.$store.state.isReadyToStart
    },
    showStartButton() {
      return (this.$store.state.simulationState === SimStateInitial)
          || (this.$store.state.simulationState === SimStatePaused)
    },
    showPauseButton() {
      return this.$store.state.simulationState === SimStateRunning
    },
    simulationState() {
      return this.$store.state.simulationState
    },
  },
  mounted() {
    // Simulation is now ready to start, since all Nodes and Users have been initialized.
    this.$store.commit('updateSimulationState', SimStateInitial)

    // Setup simulator clock ticks
    const fn = () => {
      if (this.$store.state.simulationState == SimStateRunning) {
        this.$store.commit('incrementClockTick')

        if (this.$store.state.splitDetected) {
          this.$store.commit('decrementSplitTimer')
          if (this.$store.state.splitTimer == 0) {
            this.$store.commit('updateSplitDetected', false)
          }
        }
      }
    }
    setInterval(fn, 1000)

  },
  methods: {
    elapsedTime() {
      const elapsedSeconds = this.$store.state.clockTick
      const minutes = Math.floor(elapsedSeconds / 60) % 60
      const seconds = Math.floor(elapsedSeconds % 60)
      const hours = Math.floor(elapsedSeconds / 3600)
      const hoursText = hours < 10 ? '0' + hours : hours
      const minutesText = minutes < 10 ? '0' + minutes : minutes
      const secondsText = seconds < 10 ? '0' + seconds : seconds
      return '' + hoursText + ':' + minutesText + ':' + secondsText
    },
    startSim() {
        api.startSimulator()
        .then(() => {
          this.$store.commit('updateSimulationState', SimStateRunning)
          this.$store.commit('setClockTick', 0)
        })
    },
    pauseSim() {
      api.stopSimulator()
          .then(() => {
            this.$store.commit('updateSimulationState', SimStatePaused)
          })
    }
  }
}
</script>

<style scoped>
.control-bar-bg-color {
  background: #479997;
}
</style>
