import axios from 'axios';

// create an axios instance with default options
const http = axios.create({ baseURL: window.location.origin });
// const http = axios.create({ baseURL: "http://localhost:3321" });

export default {
    getSimulatorStatus() {
        return http.get('/api/admin/simulation/status')
    },
    startSimulator() {
        return http.post('/api/admin/simulation/start')
    },
    stopSimulator() {
        return http.delete('/api/admin/simulation/start')
    },
    getSimulatorConfigs() {
        return http.get('/api/admin/simulation/genesis')
    },
    setupSimulatorConfigs(data: {nodeCount: number}) {
        return http.post('/api/admin/simulation/genesis', data)
    },
    getActiveNodes() {
        return http.get('/api/nodes')
    },
    createNode(data: { type: string, count: number }) {
        return http.post('/api/nodes', data)
    },
    deleteNode(ip: string) {
        return http.delete(`/api/nodes?ip=${ip}`)
    },
};
